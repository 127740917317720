
.checkout-step {
  .checkout-step-title {
    margin-bottom: var(--margin-m);
    font-weight: 200;
    font-size: var(--font-3xl);
  }

  .checkout-step-inner {
    padding: var(--margin-xl) var(--margin-l);
  }

  &.complete {
    border: 1px solid var(--c-success);
    .checkout-step-inner {
      padding: var(--margin-l) var(--margin-l);
    }
  }

  .edit-icon {
    position: absolute;
    cursor: pointer;
    top: 0;
    inset-inline-start: 0;
    transform: translateY(-50%) translateX(-50%);

    [dir=rtl] & {
      transform: translateY(-50%) translateX(50%);
    }
  }
}
